html,
body, #root {
  height: 100%;
  width: 100%;
}
body
{

font-family:Cerebri Sans,sans-serif;font-size:.9375rem;font-weight:400;line-height:1.5;margin:0;text-align:left;color:#003a82;background-color:#f9fbfd;
background-color: #f5f5f5;
}
.container{
  padding-top: 4.5rem; 
}
body {
  font-family: sans-serif;
}

input:not([type='checkbox']) {
  border: 1px solid #999;
  padding: 0.5rem;
}
input[type='checkbox'] {
  position: static;
  width: 30px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  position: absolute;
  z-index: 100;
  background: #f5f5f5;
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(250px + 1rem);
}
.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #003a82!important;
  color: #f5f5f5;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.tag{
  color: #c5cedb!important;
  padding: .75rem!important;
  margin: .1875rem!important;
  float: left!important;
  border-radius: .375rem!important;
  background-color: #3a567a!important;
}
.tag-selected{
  padding: .75rem!important;
  margin: .1875rem!important;
  float: left!important;
  border-radius: .375rem!important;
  background-color: #2c7be5!important;
  color: #ffffff!important;
}
.tag:hover{
  background-color: #003a82!important;
  color: #ffffff!important;
  cursor: pointer;
}

.form-checkbox{
  border: 1px solid #999;
}
@media print {
  * {
     -webkit-print-color-adjust: exact !important;
  }
}
.table-curved {
  border-collapse: separate;
  width:100%;
  border-spacing:0px;
}

.table-curved1 tr {
  position: relative;
  border-radius: 26px;
  border-collapse: separate;
  border-spacing: 2px;
  background-position-x: 10px;
  padding:10px 10px;
  transition:background .1s;
}
.table-curved tr:hover {
  background:#eee;
}


.table-curved tr:last-child td:first-child {
  border-radius: 0 0 0 6px;
}
.table-curved tr:last-child td:last-child {
  border-radius: 0 0 6px 0;
}
.table-curved  td, .table-curved th {
  padding: .9375rem;
  vertical-align: top;
  border-top: 1px solid #edf2f9;
}
#google-map{
  width: 100%;
  height: 100%;
}
.flex-grow {
  flex: 1 0 auto;
}

.issue-Title {
  padding: .9375rem;
  vertical-align: top;
  border-top: 1px solid #edf2f9;
}
.shadow {
  box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

.border-left-success {
  border-left: .25rem solid #1cc88a!important;
}
